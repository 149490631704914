import { css } from '@emotion/react'
import React from 'react'

import { baseGrid, mq } from '../theme/mixins'
import AnimateIn from './AnimateIn'
import ParallaxTranslate from './ParallaxTranslate'
import VideoPlayer from './VideoPlayer'

const VideoIntro = ({ heading, bodyNode, video, ...props }) => {
  const sectionStyle = css`
    ${baseGrid}
    grid-template-rows: var(--gutter-xlg) auto;
    margin-top: calc(var(--gutter-sm) + 1rem);
    margin-bottom: var(--gutter-lg);
    ${mq().m} {
      grid-template-rows: auto var(--gutter-xlg) auto;
    }
  `
  const textboxWrapStyle = css`
    grid-column: 2 / span 6;
    grid-row: 2 / 3;
    align-self: flex-end;
    --translate-factor: -25;
    ${mq().m} {
      --translate-factor: -10;
      grid-column: 2 / span 10;
      grid-row: 2 / 4;
    }
    ${mq().s} {
      grid-column: 2 / span 12;
    }
  `
  const textboxStyle = css`
    background-color: white;
    padding: 2rem calc(var(--column-width) + var(--gutter-md)) 2rem
      var(--gutter-mlg);
    ${mq().m} {
      padding: calc(var(--gutter-xlg)) var(--gutter-mlg)
        var(--gutter-mlg);
    }
    h1 {
      font-size: var(--fs-48);
    }
    > div {
      p {
        font-size: var(--fs-24);
        font-weight: 300;
        line-height: 1.5;
      }
    }
  `
  const videoWrapStyle = css`
    grid-column: 7 / -1;
    grid-row: 1 / 3;
    z-index: 2;
    margin-bottom: var(--gutter-mlg);
    ${mq().m} {
      grid-column: 4 / -1;
    }
    ${mq().s} {
      grid-column: 3 / -1;
    }
  `
  return (
    <section css={sectionStyle} {...props}>
      <ParallaxTranslate
        css={textboxWrapStyle}
        innerCss={textboxStyle}
        fromBack
      >
        <h1>{heading}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: bodyNode.childMarkdownRemark.html,
          }}
        />
      </ParallaxTranslate>
      <AnimateIn css={videoWrapStyle}>
        <VideoPlayer video={video} />
      </AnimateIn>
    </section>
  )
}

export default VideoIntro
