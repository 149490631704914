import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import JobPostsGrid from '../components/JobPostsGrid'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import VideoIntro from '../components/VideoIntro'

const CareersPage = () => {
  const { page } = useStaticQuery(graphql`
    query {
      page: datoCmsCareersPage {
        heading
        bodyNode {
          childMarkdownRemark {
            html
          }
        }
        video {
          url
          thumbnailUrl
        }
        sectionHeading
        jobPosts {
          jobTitle
          subheading
          jobDescription
          link {
            ...ExternalLinkFragment
          }
        }
        seo {
          title
          description
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title={page.seo.title || page.heading}
        description={page.seo.description}
      />
      <VideoIntro
        heading={page.heading}
        bodyNode={page.bodyNode}
        video={page.video}
      />
      <JobPostsGrid
        heading={page.sectionHeading}
        jobs={page.jobPosts}
      />
    </Layout>
  )
}

export default CareersPage
