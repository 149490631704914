import { css } from '@emotion/react'
import { rgba } from 'polished'
import React from 'react'

import { allCaps, baseGrid, inlineLink, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import AnimateIn from './AnimateIn'
import ExternalLink from './ExternalLink'

const JobPostsGrid = ({ heading, jobs, ...props }) => {
  const sectionStyle = css`
    ${baseGrid}
    margin: var(--gutter-md) 0 var(--gutter-xlg);
  `
  const headingStyle = css`
    grid-column: 2 / -2;
    border-top: 1px solid ${rgba(colors.grayLight, 0.5)};
    margin-bottom: var(--gutter-md);
    h2 {
      font-family: var(--sans-serif);
      font-size: var(--fs-15);
      ${allCaps}
      font-weight: 500;
      background-color: white;
      width: fit-content;
      padding: 0.75em 0.8em;
      margin: 0;
    }
  `
  const gridStyle = css`
    grid-column: 2 / -2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--gutter-md);
    ${mq().m} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${mq().s} {
      grid-template-columns: 1fr;
    }
  `
  const jobStyle = css`
    padding: 1.5rem 2rem 2rem;
    background-color: white;
    h3 {
      font-family: var(--slab-serif);
      font-size: var(--fs-24);
      font-weight: 400;
      margin: 0.5em 0 0.5em;
    }
    h4 {
      ${allCaps};
      color: ${colors.grayMid};
      font-size: var(--fs-13);
      font-weight: 500;
      margin: 0;
    }
    p {
      font-size: var(--fs-16);
    }
  `
  const linkStyle = css`
    font-size: var(--fs-15);
    ${inlineLink}
    font-weight: 700;
  `
  return (
    <AnimateIn innerCss={sectionStyle} {...props}>
      <div css={headingStyle}>
        <h2>{heading}</h2>
      </div>
      <div css={gridStyle}>
        {jobs.map((job, i) => (
          <div key={i} css={jobStyle}>
            <h3>{job.jobTitle}</h3>
            {job.subheading && <h4>{job.subheading}</h4>}
            <p
              dangerouslySetInnerHTML={{
                __html: job.jobDescription.replace(/\n/g, ' '),
              }}
            />
            <ExternalLink css={linkStyle} data={job.link[0]} />
          </div>
        ))}
      </div>
    </AnimateIn>
  )
}

export default JobPostsGrid
